import {
  Action,
  ActionType,
  AddDomainApiPayload,
  AddDomainEmailApiPayload,
  AddLicensesApiPayload,
  AddLicensesByCSVApiPayload,
  AddTeamApiPayload,
  DeleteDomainApiPayload,
  DeleteDomainEmailApiPayload,
  DeleteSubscriptionItemApiPayload,
  Domain,
  EmptyAction,
  Invoice,
  RemovedUserCount,
  Subscription,
  SubscriptionDetails,
  UpdateLicenseApiPayload,
  UpdateTeamApiPayload,
  UpdateUserApiPayload,
} from "src/domain/desktop"
import { User } from "../../../domain/system"
import { Team } from "../../../domain/desktop/common"

export const clearSubscriptionStateRequest = (payload?: number) => ({
  type: ActionType.CLEAR_SUBSCRIPTION_STATE_REQUEST,
  payload,
})

export const getSubscriptionDetailsRequest = (payload?: number) => ({
  type: ActionType.GET_SUBSCRIPTION_DETAILS_REQUEST,
  payload,
})

export const getSubscriptionDetailsSuccess = (
  payload: SubscriptionDetails
) => ({
  type: ActionType.GET_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
})

export const getSubscriptionDetailsFailure = (payload: string) => ({
  type: ActionType.GET_SUBSCRIPTION_DETAILS_FAILURE,
  payload,
})

export const getSubscriptionRequest = (payload: string) => ({
  type: ActionType.GET_SUBSCRIPTION_REQUEST,
  payload,
})

export const getSubscriptionSuccess = (payload: Subscription) => ({
  type: ActionType.GET_SUBSCRIPTION_SUCCESS,
  payload,
})

export const getSubscriptionFailure = (payload: string) => ({
  type: ActionType.GET_SUBSCRIPTION_FAILURE,
  payload,
})

export const getLicensedUsersRequest = (payload: number) => ({
  type: ActionType.GET_LICENCED_USERS_REQUEST,
  payload,
})

export const getLicensedUsersSuccess = (payload: Array<User>) => ({
  type: ActionType.GET_LICENCED_USERS_SUCCESS,
  payload,
})

export const getLicensedUsersFailure = (payload: string) => ({
  type: ActionType.GET_LICENCED_USERS_FAILURE,
  payload,
})

export const getRemovedUserCountsRequest = (payload: number) => ({
  type: ActionType.GET_REMOVED_USER_COUNTS_REQUEST,
  payload,
})

export const getRemovedUserCountsSuccess = (
  payload: Array<RemovedUserCount>
) => ({
  type: ActionType.GET_REMOVED_USER_COUNTS_SUCCESS,
  payload,
})

export const getRemovedUserCountsFailure = (payload: string) => ({
  type: ActionType.GET_REMOVED_USER_COUNTS_FAILURE,
  payload,
})

export const getRemovedUsersRequest = (payload: number) => ({
  type: ActionType.GET_REMOVED_USERS_REQUEST,
  payload,
})

export const getRemovedUsersSuccess = (payload: Array<User>) => ({
  type: ActionType.GET_REMOVED_USERS_SUCCESS,
  payload,
})

export const getRemovedUsersFailure = (payload: string) => ({
  type: ActionType.GET_REMOVED_USERS_FAILURE,
  payload,
})

export const getInvoicesRequest = (payload: number) => ({
  type: ActionType.GET_INVOICES_REQUEST,
  payload,
})

export const getInvoicesSuccess = (payload: Array<Invoice>) => ({
  type: ActionType.GET_INVOICES_SUCCESS,
  payload,
})

export const getInvoicesFailure = (payload: string) => ({
  type: ActionType.GET_INVOICES_FAILURE,
  payload,
})

export const createLicenceManagerRequest = (payload: {
  id: number
  data: User
}) => ({
  type: ActionType.CREATE_LICENSE_MANAGER_REQUEST,
  payload,
})

export const createLicenceManagerSuccess = (payload: Array<User>) => ({
  type: ActionType.CREATE_LICENSE_MANAGER_SUCCESS,
  payload,
})

export const createLicenceManagerFailure = (payload: string) => ({
  type: ActionType.CREATE_LICENSE_MANAGER_FAILURE,
  payload,
})

export const createBillingUserRequest = (payload: {
  id: number
  data: User
}) => ({
  type: ActionType.CREATE_BILLING_USER_REQUEST,
  payload,
})

export const createBillingUserSuccess = (payload: Array<User>) => ({
  type: ActionType.CREATE_BILLING_USER_SUCCESS,
  payload,
})

export const createBillingUserFailure = (payload: string) => ({
  type: ActionType.CREATE_BILLING_USER_FAILURE,
  payload,
})

export const addLicenseRequest = (payload: AddLicensesApiPayload) => ({
  type: ActionType.ADD_LICENSE_REQUEST,
  payload,
})

export const addLicenseSuccess = (payload: {
  status?: number
  message?: string
  license?: User
}) => ({
  type: ActionType.ADD_LICENSE_SUCCESS,
  payload,
})

export const addLicenseFailure = (payload: string) => ({
  type: ActionType.ADD_LICENSE_FAILURE,
  payload,
})

export const addLicensesByCSVRequest = (
  payload: AddLicensesByCSVApiPayload
): Action<AddLicensesByCSVApiPayload> => ({
  type: ActionType.ADD_LICENSES_CSV_REQUEST,
  payload,
})

export const addLicensesByCSVSuccess = (): EmptyAction => ({
  type: ActionType.ADD_LICENSES_CSV_SUCCESS,
})

export const addLicensesByCSVFailure = (payload: string): Action<string> => ({
  type: ActionType.ADD_LICENSES_CSV_FAILURE,
  payload,
})

export const generateLicenseTokenRequest = (
  payload: AddLicensesApiPayload
) => ({
  type: ActionType.GENERATE_LICENSE_TOKEN_REQUEST,
  payload,
})

export const generateLicenseTokenSuccess = (payload: {
  status?: number
  message?: string
}) => ({
  type: ActionType.GENERATE_LICENSE_TOKEN_SUCCESS,
  payload,
})

export const generateLicenseTokenFailure = (payload: string) => ({
  type: ActionType.GENERATE_LICENSE_TOKEN_FAILURE,
  payload,
})

export const deleteLicenseTokenRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_LICENSE_TOKEN_REQUEST,
  payload,
})

export const deleteLicenseTokenSuccess = () => ({
  type: ActionType.DELETE_LICENSE_TOKEN_SUCCESS,
})

export const deleteeLicenseTokenFailure = (payload: string) => ({
  type: ActionType.DELETE_LICENSE_TOKEN_FAILURE,
  payload,
})

export const createTeamRequest = (payload: AddTeamApiPayload) => ({
  type: ActionType.CREATE_TEAM_REQUEST,
  payload,
})

export const createTeamSuccess = (payload: Array<Team>) => ({
  type: ActionType.CREATE_TEAM_SUCCESS,
  payload,
})

export const createTeamFailure = (payload: string) => ({
  type: ActionType.CREATE_TEAM_FAILURE,
  payload,
})

export const deleteTeamRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_TEAM_REQUEST,
  payload,
})

export const deleteTeamSuccess = (payload: Array<Team>) => ({
  type: ActionType.DELETE_TEAM_SUCCESS,
  payload,
})

export const deleteTeamFailure = (payload: string) => ({
  type: ActionType.DELETE_TEAM_FAILURE,
  payload,
})

export const updateTeamRequest = (payload: UpdateTeamApiPayload) => ({
  type: ActionType.UPDATE_TEAM_REQUEST,
  payload,
})

export const updateTeamSuccess = (payload: Array<Team>) => ({
  type: ActionType.UPDATE_TEAM_SUCCESS,
  payload,
})

export const updateTeamFailure = (payload: string) => ({
  type: ActionType.UPDATE_TEAM_FAILURE,
  payload,
})

export const updateSubscriptionRequest = (payload: {
  subscription: Subscription
  isAdminApi: boolean
}) => ({
  type: ActionType.UPDATE_SUBSCRIPTION_REQUEST,
  payload,
})

export const updateSubscriptionSuccess = (payload: Subscription) => ({
  type: ActionType.UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
})

export const updateSubscriptionFailure = (payload: string) => ({
  type: ActionType.UPDATE_SUBSCRIPTION_FAILURE,
  payload,
})

export const deleteBillingUserRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_BILLING_USER_REQUEST,
  payload,
})

export const deleteBillingUserSuccess = (payload: Array<User>) => ({
  type: ActionType.DELETE_BILLING_USER_SUCCESS,
  payload,
})

export const deleteBillingUserFailure = (payload: string) => ({
  type: ActionType.DELETE_BILLING_USER_FAILURE,
  payload,
})

export const deleteAdminOfSubscriptionRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST,
  payload,
})

export const deleteAdminOfSubscriptionSuccess = (payload: Array<User>) => ({
  type: ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_SUCCESS,
  payload,
})

export const deleteAdminOfSubscriptionFailure = (payload: string) => ({
  type: ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_FAILURE,
  payload,
})

export const deleteLicenseManagerRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_LICENSE_MANAGER_REQUEST,
  payload,
})

export const deleteLicenseManagerSuccess = (payload: Array<User>) => ({
  type: ActionType.DELETE_LICENSE_MANAGER_SUCCESS,
  payload,
})

export const deleteLicenseManagerFailure = (payload: string) => ({
  type: ActionType.DELETE_LICENSE_MANAGER_FAILURE,
  payload,
})

export const deleteLicensedUserRequest = (
  payload: DeleteSubscriptionItemApiPayload
) => ({
  type: ActionType.DELETE_LICENSED_USER_REQUEST,
  payload,
})

export const deleteLicensedUserSuccess = (payload: Array<User>) => ({
  type: ActionType.DELETE_LICENSED_USER_SUCCESS,
  payload,
})

export const deleteLicensedUserFailure = (payload: string) => ({
  type: ActionType.DELETE_LICENSED_USER_FAILURE,
  payload,
})

export const updateLicensedUserRequest = (
  payload: UpdateLicenseApiPayload
) => ({
  type: ActionType.UPDATE_LICENSE_REQUEST,
  payload,
})

export const updateLicensedUserSuccess = (payload?: User) => ({
  type: ActionType.UPDATE_LICENSE_SUCCESS,
  payload,
})

export const updateLicensedUserFailure = (payload: string) => ({
  type: ActionType.UPDATE_LICENSE_FAILURE,
  payload,
})

export const updateUserRequest = (payload: UpdateUserApiPayload) => ({
  type: ActionType.UPDATE_USER_REQUEST,
  payload,
})

export const updateUserSuccess = (payload: User) => ({
  type: ActionType.UPDATE_USER_SUCCESS,
  payload,
})

export const updateUserFailure = (payload: string) => ({
  type: ActionType.UPDATE_USER_FAILURE,
  payload,
})

export const addDomainRequest = (payload: AddDomainApiPayload) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_REQUEST,
  payload,
})

export const addDomainSuccess = (payload: Domain[]) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_SUCCESS,
  payload,
})

export const addDomainFailure = (payload: string) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_FAILURE,
  payload,
})

export const deleteDomainRequest = (payload: DeleteDomainApiPayload) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_REQUEST,
  payload,
})

export const deleteDomainSuccess = (payload: Domain[]) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_SUCCESS,
  payload,
})

export const deleteDomainFailure = (payload: string) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_FAILURE,
  payload,
})

export const addDomainEmailRequest = (payload: AddDomainEmailApiPayload) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST,
  payload,
})

export const addDomainEmailSuccess = (payload: Domain[]) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS,
  payload,
})

export const addDomainEmailFailure = (payload: string) => ({
  type: ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE,
  payload,
})

export const deleteDomainEmailRequest = (
  payload: DeleteDomainEmailApiPayload
) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST,
  payload,
})

export const deleteDomainEmailSuccess = (payload: Domain[]) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_SUCCESS,
  payload,
})

export const deleteDomainEmailFailure = (payload: string) => ({
  type: ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_FAILURE,
  payload,
})

export const selectUser = (payload?: User) => ({
  type: ActionType.SELECT_USER,
  payload,
})
