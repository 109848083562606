import { LayoutHeader } from "../../layouts/layouts.styles"
import React from "react"
import { Flex } from "@conduktor/ui-library"

export const Header: React.FC = () => {
  return (
    <LayoutHeader>
      <Flex align="center" css={{ color: "grey" }}>
        This portal manages Conduktor Desktop only, not&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.conduktor.io/get-started/"
        >
          Conduktor Platform
        </a>
        &nbsp;or its users which are independent.
      </Flex>
    </LayoutHeader>
  )
}
