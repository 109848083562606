import { all, put, select, takeLatest } from "redux-saga/effects"
import { AppState } from "../../config/rootStore"
import {
  addDomainApi,
  addDomainEmailApi,
  addLicensesApi,
  addLicensesByCSVApi,
  createAdminBillingUserOfSubscriptoinApi,
  createSubscriptionTechnicalManagerApi,
  deleteAdminOfSubscriptionApi,
  deleteBillingUserApi,
  deleteDomainApi,
  deleteDomainEmailApi,
  deleteLicenseApi,
  deleteLicenseTokenApi,
  deleteTechicalManagerApi,
  generateLicensesTokenApi,
  getAdminLicensesRemovedCountApi,
  getAdminSubscriptionDetailsApi,
  getLicensesApi,
  getLicensesRemovedApi,
  getSubscriptionDetailsApi,
  getSubscriptionInvoicesApi,
  getSubscriptionsApi,
  updateAdminSubscriptionApi,
  updateLicenseApi,
  updateSubscriptionApi,
  updateUserApi,
} from "src/services"
import {
  Action,
  ActionType,
  AddDomainApiPayload,
  AddDomainEmailApiPayload,
  AddLicensesApiPayload,
  AddLicensesByCSVApiPayload,
  AddTeamApiPayload,
  DeleteDomainApiPayload,
  DeleteDomainEmailApiPayload,
  DeleteSubscriptionItemApiPayload,
  Domain,
  Invoice,
  RemovedUserCount,
  Subscription,
  SubscriptionDetails,
  UpdateLicenseApiPayload,
  UpdateTeamApiPayload,
  UpdateUserApiPayload,
} from "src/domain/desktop"
import { parseError } from "src/utils"
import { isValidUser } from "src/domain/user"
import {
  addDomainEmailFailure,
  addDomainEmailSuccess,
  addDomainFailure,
  addDomainSuccess,
  addLicenseFailure,
  addLicensesByCSVFailure,
  addLicensesByCSVSuccess,
  addLicenseSuccess,
  createBillingUserFailure,
  createBillingUserSuccess,
  createLicenceManagerFailure,
  createLicenceManagerSuccess,
  createTeamFailure,
  createTeamSuccess,
  deleteAdminOfSubscriptionFailure,
  deleteAdminOfSubscriptionSuccess,
  deleteBillingUserFailure,
  deleteBillingUserSuccess,
  deleteDomainEmailFailure,
  deleteDomainEmailSuccess,
  deleteDomainFailure,
  deleteDomainSuccess,
  deleteeLicenseTokenFailure,
  deleteLicensedUserFailure,
  deleteLicensedUserSuccess,
  deleteLicenseManagerFailure,
  deleteLicenseManagerSuccess,
  deleteLicenseTokenSuccess,
  deleteTeamFailure,
  deleteTeamSuccess,
  generateLicenseTokenFailure,
  generateLicenseTokenSuccess,
  getInvoicesFailure,
  getInvoicesSuccess,
  getLicensedUsersFailure,
  getLicensedUsersRequest,
  getLicensedUsersSuccess,
  getRemovedUserCountsFailure,
  getRemovedUserCountsRequest,
  getRemovedUserCountsSuccess,
  getRemovedUsersFailure,
  getRemovedUsersRequest,
  getRemovedUsersSuccess,
  getSubscriptionDetailsFailure,
  getSubscriptionDetailsSuccess,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  updateLicensedUserFailure,
  updateLicensedUserSuccess,
  updateSubscriptionFailure,
  updateSubscriptionSuccess,
  updateTeamFailure,
  updateTeamSuccess,
  updateUserFailure,
  updateUserSuccess,
} from "./actions"
import {
  selectConduktorUser,
  selectIsLicensed,
  selectLicensedUser,
} from "../../system/selector"
import {
  fetchAllDesktopUserData,
  hideModal,
  setConduktorUser,
  setLicensedUser,
  setMessage,
} from "../../system/actions"
import {
  addTeamApi,
  deleteTeamApi,
  updateTeamApi,
} from "src/services/modules/teams"
import { getSubscriptionsRequest } from "../subscriptions/actions"
import { selectSubscriptionLicensedUsers } from "./selector"
import {
  License,
  ModalType,
  Team,
  UserRoleType,
} from "../../../domain/desktop/common"
import { ConduktorUser, MessageType, User } from "../../../domain/system"
import { ClientError } from "src/services/ClientError"

function* getSubscriptionDetailsSaga({ payload: id }: Action<number>) {
  try {
    const data: SubscriptionDetails = yield getSubscriptionDetailsApi(id)
    yield put(getSubscriptionDetailsSuccess(data))
  } catch (err) {
    yield put(getSubscriptionDetailsFailure(parseError(err as ClientError)))
  }
}

function* isAdminUser() {
  const state: AppState = yield select()
  const conduktorUser: ConduktorUser = selectConduktorUser(state)
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])
  return isAdmin
}

function* getSubscriptionSaga({ payload: id }: Action<string>) {
  try {
    const isAdmin: boolean = yield isAdminUser()

    if (isAdmin) {
      const sub: Subscription = yield getAdminSubscriptionDetailsApi(id)
      yield put(getSubscriptionSuccess(sub))
      return
    }
    const subs: Subscription[] = yield getSubscriptionsApi()
    const sub = subs.find((s) => s.chargeBeeSubscriptionId === id)
    if (sub) {
      yield put(getSubscriptionSuccess(sub))
      return
    }
    yield put(getSubscriptionFailure("The subscription could not be found"))
  } catch (err) {
    yield put(getSubscriptionFailure(parseError(err as ClientError)))
  }
}

function* getLicensedUsersSaga({ payload: id }: Action<number>) {
  try {
    const data: Array<User> = yield getLicensesApi(id)
    yield put(getLicensedUsersSuccess(data))
  } catch (err) {
    yield put(getLicensedUsersFailure(parseError(err as ClientError)))
  }
}

function* getRemovedUsersSaga({ payload: id }: Action<number>) {
  try {
    const data: Array<User> = yield getLicensesRemovedApi(id)
    yield put(getRemovedUsersSuccess(data))
  } catch (err) {
    yield put(getRemovedUsersFailure(parseError(err as ClientError)))
  }
}

function* getRemovedUserCountsSaga({ payload: id }: Action<number>) {
  try {
    const isAdmin: boolean = yield isAdminUser()

    if (!isAdmin) {
      yield put(getRemovedUserCountsSuccess([]))
    } else {
      const data: Array<RemovedUserCount> = yield getAdminLicensesRemovedCountApi(
        id
      )
      yield put(getRemovedUserCountsSuccess(data))
    }
  } catch (err) {
    yield put(getRemovedUserCountsFailure(parseError(err as ClientError)))
  }
}

function* getInvoicesSaga({ payload: id }: Action<number>) {
  try {
    const data: Array<Invoice> = yield getSubscriptionInvoicesApi(id)
    yield put(getInvoicesSuccess(data))
  } catch (err) {
    yield put(getInvoicesFailure(parseError(err as ClientError)))
  }
}

function* createLicenseManagerSaga({
  payload,
}: Action<{ id: number; data: User }>) {
  try {
    const data: Array<User> = yield createSubscriptionTechnicalManagerApi(
      payload
    )
    yield put(createLicenceManagerSuccess(data))
  } catch (err) {
    yield put(createLicenceManagerFailure(parseError(err as ClientError)))
  }
}

function* createBillingUserSaga({
  payload,
}: Action<{ id: number; data: User }>) {
  try {
    const data: Array<User> = yield createAdminBillingUserOfSubscriptoinApi(
      payload
    )
    yield put(createBillingUserSuccess(data))
  } catch (err) {
    yield put(createBillingUserFailure(parseError(err as ClientError)))
  }
}

function* addLicenseSaga({ payload }: Action<AddLicensesApiPayload>) {
  const state: AppState = yield select()
  const conduktorUser: ConduktorUser = selectConduktorUser(state)

  try {
    const data: {
      status?: number
      message?: string
      license?: License
    } = yield addLicensesApi(payload)
    if (data.status === 1) {
      yield put(addLicenseSuccess(data))
      yield put(hideModal(ModalType.ADD_USER))
      yield put(
        setMessage({
          message: `Added successfully!`,
          type: MessageType.SUCCESS,
        })
      )
      if (payload.email === conduktorUser.email) {
        yield put(fetchAllDesktopUserData(true))
      }
      if (data.license?.subscription) {
        yield put(getLicensedUsersRequest(data.license?.subscription.id))
      }
    } else if (data.status === 2) {
      yield put(addLicenseFailure(data?.message || "Something went wrong!"))
    } else if (data.status === 3) {
      yield put(
        addLicenseFailure(
          data?.message || "This member already exist in a subscription"
        )
      )
    } else {
      yield put(addLicenseFailure("Something went wrong!"))
    }
  } catch (err) {
    yield put(addLicenseFailure(parseError(err as ClientError)))
  }
}

function* addLicensesByCSVSaga({
  payload,
}: Action<AddLicensesByCSVApiPayload>) {
  try {
    yield addLicensesByCSVApi(payload)
    yield put(addLicensesByCSVSuccess())
    yield put(getLicensedUsersRequest(payload.subscriptionId))
    yield put(hideModal(ModalType.ADD_USER_CSV))
    yield put(
      setMessage({
        message: `Imported successfully!`,
        type: MessageType.SUCCESS,
      })
    )
  } catch (err) {
    yield put(addLicensesByCSVFailure(parseError(err as ClientError)))
  }
}

function* generateLicenseTokenSaga({ payload }: Action<AddLicensesApiPayload>) {
  const state: AppState = yield select()
  const conduktorUser: ConduktorUser = selectConduktorUser(state)
  const isAdmin = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  try {
    const data: {
      status?: number
      message?: string
    } = yield generateLicensesTokenApi(payload)
    if (data.status === 1) {
      yield put(generateLicenseTokenSuccess(data))
      if (payload.email === conduktorUser.email) {
        yield put(fetchAllDesktopUserData(true))
      }
      if (isAdmin) {
        yield put(getSubscriptionsRequest(true))
      }
    } else if (data.status === 2) {
      yield put(
        generateLicenseTokenFailure(data?.message || "Something went wrong!")
      )
    } else {
      yield put(generateLicenseTokenFailure("Something went wrong!"))
    }
  } catch (err) {
    yield put(generateLicenseTokenFailure(parseError(err as ClientError)))
  }
}

function* deleteLicenseTokenSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    yield deleteLicenseTokenApi(payload)
    yield put(getLicensedUsersRequest(payload.subscriptionId))
    yield put(getRemovedUsersRequest(payload.subscriptionId))
    yield put(getRemovedUserCountsRequest(payload.subscriptionId))
    yield put(deleteLicenseTokenSuccess())
  } catch (err) {
    yield put(deleteeLicenseTokenFailure(parseError(err as ClientError)))
  }
}

function* createTeamSaga({ payload }: Action<AddTeamApiPayload>) {
  try {
    const data: Array<Team> = yield addTeamApi(payload)
    yield put(createTeamSuccess(data))
  } catch (err) {
    yield put(createTeamFailure(parseError(err as ClientError)))
  }
}

function* deleteTeamSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    const data: Array<Team> = yield deleteTeamApi(payload)
    yield put(deleteTeamSuccess(data))
  } catch (err) {
    yield put(deleteTeamFailure(parseError(err as ClientError)))
  }
}

function* updateTeamSaga({ payload }: Action<UpdateTeamApiPayload>) {
  try {
    const data: Array<Team> = yield updateTeamApi(payload)
    yield put(updateTeamSuccess(data))
  } catch (err) {
    yield put(updateTeamFailure(parseError(err as ClientError)))
  }
}

function* updateSubscriptionSaga({
  payload,
}: Action<{
  subscription: Subscription
  isAdminApi: boolean
}>) {
  try {
    const data: Subscription = !payload.isAdminApi
      ? yield updateSubscriptionApi(payload.subscription)
      : yield updateAdminSubscriptionApi(payload.subscription)
    yield put(updateSubscriptionSuccess(data))
  } catch (err) {
    yield put(updateSubscriptionFailure(parseError(err as ClientError)))
  }
}

function* deleteBillingUserSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    const data: Array<User> = yield deleteBillingUserApi(payload)
    yield put(deleteBillingUserSuccess(data))
  } catch (err) {
    yield put(deleteBillingUserFailure(parseError(err as ClientError)))
  }
}

function* deleteAdminOfSubscriptionSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    const data: Array<User> = yield deleteAdminOfSubscriptionApi(payload)
    yield put(deleteAdminOfSubscriptionSuccess(data))
  } catch (err) {
    yield put(deleteAdminOfSubscriptionFailure(parseError(err as ClientError)))
  }
}

function* deleteLicenseManagerSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    const data: Array<User> = yield deleteTechicalManagerApi(payload)
    yield put(deleteLicenseManagerSuccess(data))
  } catch (err) {
    yield put(deleteLicenseManagerFailure(parseError(err as ClientError)))
  }
}

function* deleteLicensedUserSaga({
  payload,
}: Action<DeleteSubscriptionItemApiPayload>) {
  try {
    yield deleteLicenseApi(payload)
    // Update the licensed users list
    const state: AppState = yield select()
    const users: User[] = selectSubscriptionLicensedUsers(state) || []
    const remainingUsers = users.filter((u) => u.id !== payload.id)
    yield put(deleteLicensedUserSuccess(remainingUsers))
    // Update state to consider the deleted license
    yield put(getRemovedUsersRequest(payload.subscriptionId))
    yield put(getRemovedUserCountsRequest(payload.subscriptionId))
  } catch (err) {
    yield put(deleteLicensedUserFailure(parseError(err as ClientError)))
  }
}

function* updateLicensedUserSaga({ payload }: Action<UpdateLicenseApiPayload>) {
  try {
    const data: User = yield updateLicenseApi(payload)
    const state: AppState = yield select()
    const licensedUser: User = selectLicensedUser(state)
    const isLicensed: boolean = selectIsLicensed(state)
    if (isLicensed && licensedUser?.id === payload.id) {
      yield put(
        setLicensedUser({
          ...licensedUser,
          name: data.name,
        })
      )
    }
    yield put(updateLicensedUserSuccess())
    if (payload.subscription && payload.subscription.id) {
      yield put(getLicensedUsersRequest(payload.subscription.id))
    }
  } catch (err) {
    yield put(updateLicensedUserFailure(parseError(err as ClientError)))
  }
}

function* updateUserSaga({ payload }: Action<UpdateUserApiPayload>) {
  try {
    const data: User = yield updateUserApi(payload)

    const state: AppState = yield select()
    const conduktorUser: ConduktorUser = selectConduktorUser(state)

    if (conduktorUser?.id && conduktorUser.id === data.id) {
      yield put(setConduktorUser(data))
    }

    yield put(
      updateUserSuccess({
        ...data,
        accessLevel: payload.user?.accessLevel,
      })
    )
  } catch (err) {
    yield put(updateUserFailure(parseError(err as ClientError)))
  }
}

function* addSubscriptionDomainSaga({ payload }: Action<AddDomainApiPayload>) {
  try {
    const data: Array<Domain> = yield addDomainApi(payload)
    yield put(addDomainSuccess(data))
  } catch (err) {
    yield put(addDomainFailure(parseError(err as ClientError)))
  }
}

function* deleteSubscriptionDomainSaga({
  payload,
}: Action<DeleteDomainApiPayload>) {
  try {
    const data: Array<Domain> = yield deleteDomainApi(payload)
    yield put(deleteDomainSuccess(data))
  } catch (err) {
    yield put(deleteDomainFailure(parseError(err as ClientError)))
  }
}

function* addSubscriptionDomainEmailSaga({
  payload,
}: Action<AddDomainEmailApiPayload>) {
  try {
    const data: Array<Domain> = yield addDomainEmailApi(payload)
    yield put(addDomainEmailSuccess(data))
  } catch (err) {
    yield put(addDomainEmailFailure(parseError(err as ClientError)))
  }
}

function* deleteSubscriptionDomainEmailSaga({
  payload,
}: Action<DeleteDomainEmailApiPayload>) {
  try {
    const data: Array<Domain> = yield deleteDomainEmailApi(payload)
    yield put(deleteDomainEmailSuccess(data))
  } catch (err) {
    yield put(deleteDomainEmailFailure(parseError(err as ClientError)))
  }
}

export default function* subscriptionDetailsSagas() {
  yield all([
    takeLatest(ActionType.GET_SUBSCRIPTION_REQUEST, getSubscriptionSaga),
    takeLatest(
      ActionType.GET_SUBSCRIPTION_DETAILS_REQUEST,
      getSubscriptionDetailsSaga
    ),
    takeLatest(ActionType.GET_LICENCED_USERS_REQUEST, getLicensedUsersSaga),
    takeLatest(ActionType.GET_REMOVED_USERS_REQUEST, getRemovedUsersSaga),

    takeLatest(
      ActionType.GET_REMOVED_USER_COUNTS_REQUEST,
      getRemovedUserCountsSaga
    ),
    takeLatest(ActionType.GET_INVOICES_REQUEST, getInvoicesSaga),
    takeLatest(
      ActionType.CREATE_LICENSE_MANAGER_REQUEST,
      createLicenseManagerSaga
    ),
    takeLatest(ActionType.CREATE_BILLING_USER_REQUEST, createBillingUserSaga),

    takeLatest(ActionType.ADD_LICENSE_REQUEST, addLicenseSaga),
    takeLatest(ActionType.ADD_LICENSES_CSV_REQUEST, addLicensesByCSVSaga),
    takeLatest(ActionType.DELETE_LICENSE_TOKEN_REQUEST, deleteLicenseTokenSaga),
    takeLatest(
      ActionType.GENERATE_LICENSE_TOKEN_REQUEST,
      generateLicenseTokenSaga
    ),

    takeLatest(ActionType.UPDATE_TEAM_REQUEST, updateTeamSaga),
    takeLatest(ActionType.DELETE_TEAM_REQUEST, deleteTeamSaga),
    takeLatest(ActionType.CREATE_TEAM_REQUEST, createTeamSaga),

    takeLatest(ActionType.UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionSaga),
    takeLatest(ActionType.DELETE_LICENSED_USER_REQUEST, deleteLicensedUserSaga),

    takeLatest(
      ActionType.DELETE_LICENSE_MANAGER_REQUEST,
      deleteLicenseManagerSaga
    ),
    takeLatest(
      ActionType.DELETE_ADMIN_OF_SUBSCRIPTION_REQUEST,
      deleteAdminOfSubscriptionSaga
    ),
    takeLatest(ActionType.DELETE_BILLING_USER_REQUEST, deleteBillingUserSaga),

    takeLatest(ActionType.UPDATE_LICENSE_REQUEST, updateLicensedUserSaga),
    takeLatest(ActionType.UPDATE_USER_REQUEST, updateUserSaga),

    takeLatest(
      ActionType.ADD_SUBSCRIPTION_DOMAIN_REQUEST,
      addSubscriptionDomainSaga
    ),
    takeLatest(
      ActionType.DELETE_SUBSCRIPTION_DOMAIN_REQUEST,
      deleteSubscriptionDomainSaga
    ),
    takeLatest(
      ActionType.ADD_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST,
      addSubscriptionDomainEmailSaga
    ),
    takeLatest(
      ActionType.DELETE_SUBSCRIPTION_DOMAIN_EMAIL_REQUEST,
      deleteSubscriptionDomainEmailSaga
    ),
  ])
}
