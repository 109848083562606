import {
  AddClusterApiPayload,
  Cluster,
  DeleteClusterApiPayload,
  DeleteSubscriptionItemApiPayload,
  EstimateLicensesApiResult,
  GetClusterApiPayload,
  IncreaseLicensesApiPayload,
  Subscription,
  SubscriptionApiPayload,
} from "src/domain/desktop"
import { desktopClient } from "../axiosClients"
import { User } from "../../domain/system"

export const getSubscriptionsApi = async (): Promise<Subscription[]> => {
  const { data } = await desktopClient.get("subscriptions")
  return data
}

export const updateSubscriptionApi = async (paylaod: Subscription) => {
  const { data } = await desktopClient.put("subscriptions", paylaod)
  return data
}

export const getSubscriptionDetailsApi = async (paylaod: number) => {
  const { data } = await desktopClient.get(
    `subscriptions/subscription-details/${paylaod}`
  )
  return data
}

export const getSubscriptionInvoicesApi = async (paylaod: number) => {
  const { data } = await desktopClient.get(
    `subscriptions/get-invoices/${paylaod}`
  )
  return data
}

export const getSubscriptionDownloadInvoiceApi = async ({
  id,
  invoiceId,
}: {
  id: number
  invoiceId: string
}) => {
  const { data } = await desktopClient.get(
    `subscriptions/download-invoice/${id}/${invoiceId}`
  )
  return data
}

export const createSubscriptionTechnicalManagerApi = async ({
  id,
  data: payload,
}: {
  id: number
  data: User
}) => {
  const { data } = await desktopClient.post(
    `subscriptions/technical-managers/${id}`,
    payload
  )
  return data
}

export const deleteAdminOfSubscriptionApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload) => {
  const { data } = await desktopClient.delete(
    `subscriptions/admin-of-subscription/${subscriptionId}/${id}`
  )
  return data
}

export const deleteTechicalManagerApi = async ({
  subscriptionId,
  id,
}: DeleteSubscriptionItemApiPayload) => {
  const { data } = await desktopClient.delete(
    `subscriptions/technical-managers/${subscriptionId}/${id}`
  )
  return data
}

export const estimateLicensesApi = async (
  payload: IncreaseLicensesApiPayload
): Promise<EstimateLicensesApiResult> => {
  const { data } = await desktopClient.post(`subscriptions/estimate`, payload)
  return data
}

export const generateQuoteLicensesApi = async (
  payload: IncreaseLicensesApiPayload
) => {
  const { data } = await desktopClient.post(
    `subscriptions/generate-quote`,
    payload
  )
  return data
}

export const increaseLicensesApi = async (
  payload: IncreaseLicensesApiPayload
) => {
  const { data } = await desktopClient.post(
    `subscriptions/increase-licenses`,
    payload
  )
  return data
}

export const cancelSubscriptionApi = async (
  payload: SubscriptionApiPayload
) => {
  await desktopClient.delete(`subscriptions/${payload.subscriptionId}`)
}

export const getClustersApi = async (payload: number): Promise<Cluster[]> => {
  const { data } = await desktopClient.get<Cluster[]>(
    `subscriptions/${payload}/clusters`
  )
  return data
}

export const addClusterApi = async (payload: AddClusterApiPayload) => {
  const request: Pick<Cluster, "name" | "description" | "allUsersRoles"> = {
    name: payload.clusterData.name,
    description: payload.clusterData.description,
    allUsersRoles: payload.clusterData.allUsersRoles || ["Owner"],
  }
  const { data } = await desktopClient.put(
    `subscriptions/${payload.subscriptionId}/clusters/${payload.clusterData.clusterId}`,
    request
  )
  return data
}

export const getClusterApi = async (
  payload: GetClusterApiPayload
): Promise<Cluster> => {
  const { data } = await desktopClient.get(
    `subscriptions/${payload.subscriptionId}/clusters/${payload.clusterId}`
  )

  return data
}

export const deleteClusterApi = async (payload: DeleteClusterApiPayload) => {
  await desktopClient.delete(
    `subscriptions/${payload.subscriptionId}/clusters/${payload.clusterId}`
  )
}

export const deleteClustersApi = async (payload: {
  subscriptionId: number
  clusterIds: (string | undefined)[]
}) => {
  for (let i = 0; i < payload.clusterIds.length; i++) {
    if (!payload.clusterIds[i]) continue
    await deleteClusterApi({
      clusterId: payload.clusterIds[i]!,
      subscriptionId: payload.subscriptionId,
    })
  }
}
