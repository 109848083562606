import { useSelector } from "react-redux"
import { selectSubscription } from "src/store/desktop/subscriptionDetails/selector"
import { selectConduktorUser } from "src/store/system/selector"
import { formatDate } from "src/utils"
import { getMemberNameByAccessLevel, isValidUser } from "src/domain/user"
import { Subscription, SubscriptionType } from "src/domain/desktop"
import OfflineTokenColumn from "./columns/OfflineTokenColumn"
import RevokeColumn from "./columns/RevokeColumn"
import LastActivityColumn from "./columns/LastActivityColumn"
import { ColumnType } from "antd/lib/table"
import MembersNameColumn from "./columns/MembersNameColumn"
import { ConduktorUser, User } from "../../../../../../../domain/system"
import { UserRoleType } from "../../../../../../../domain/desktop/common"

const MembersColumns = () => {
  const conduktorUser: ConduktorUser = useSelector(selectConduktorUser)
  const subscription: Subscription | undefined = useSelector(selectSubscription)
  const isAdmin: boolean = isValidUser(conduktorUser, [UserRoleType.ADMIN])

  const columns: ColumnType<User>[] = [
    {
      title: "Name",
      dataIndex: "name",
      align: "left",
      render: MembersNameColumn,
    },
    {
      title: "Assigned on",
      width: 150,
      key: "created",
      align: "center",
      render: (_text, record) => {
        return <span>{formatDate(record.created)}</span>
      },
    },
    {
      title: "Role",
      width: 150,
      key: "accessLevel",
      align: "center",
      render: (_text, record) => {
        return <span>{getMemberNameByAccessLevel(record.accessLevel)}</span>
      },
    },
  ]

  if (subscription?.hasOffline) {
    const offlineTokenColumn: ColumnType<User> = {
      key: "offlineToken",
      title: "Offline Machine",
      render: OfflineTokenColumn,
      align: "center",
      width: 200,
    }
    columns.push(offlineTokenColumn)
  }
  if (isAdmin || subscription?.showLastActivity) {
    columns.push(LastActivityColumn)
  }
  if (isAdmin || subscription?.type === SubscriptionType.ENTERPRISE) {
    const revokeColumn: ColumnType<User> = {
      title: "Revoke",
      key: "revoke",
      render: RevokeColumn,
      align: "center",
      width: 70,
    }
    columns.push(revokeColumn)
  }
  return columns
}

export default MembersColumns
